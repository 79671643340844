<template>
    <div style="width:96%; margin-left:2%">
        <div style="text-align: center; margin-top: 20px">
            <a class="title_a">Media</a><br>
            <a class="title_a1">From Our Community</a>
        </div>
        <div>
            <div style="margin-top: 40px; display: flex; flex-wrap: wrap;">
                <div class="good" v-for="item in swiperList" :key="item.id" >
                    <el-card class="card" :body-style="{ padding: '0px' }" shadow="hover">
                        <img :src="item.imgUrl" class="good_image">
                    </el-card>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
    name: "Join",
    data() {
        return {
            swiperList: [{
                id: 1,
                title: '',
                imgUrl: require("@/assets/home/Exorcism.png"),
            }, {
                id: 2,
                title: '',
                imgUrl: require("@/assets/home/Love.png"),
            }, {
                id: 3,
                title: '',
                imgUrl: require("@/assets/home/Wealth.png"),
            }, {
                id: 4,
                title: '',
                imgUrl: require("@/assets/home/Health.png"),
            }, {
                id: 5,
                title: '',
                imgUrl: require("@/assets/home/Studies.png"),
            }, {
                id: 6,
                title: '',
                imgUrl: require("@/assets/home/Luck.png"),
            }]
        }
    },
    created() {
        //this.load()
    },
    
    methods: {

    },
    mounted() {
        
    },
}
</script>

<style lang="scss" scoped>
.title_a {
    font-size: 70px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 12px;
}
.title_a1 {
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 2px;
    word-spacing: 5px;
}
.good {
    margin-left: 2%;
    width: 12%;
    height: 300px;
    text-align: center;
}
.good_image {
    width: 100%;
}
.card {
    height: 650px;
    border-radius: 0;
    border: 0 ;
    text-align: left;
    padding: 5px;
}
</style>
